import React from "react";
import { withPreviewResolver } from "gatsby-source-prismic";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { graphql } from "gatsby";
import linkResolver from "../utils/linkResolver";

const loadingG = keyframes`
 from, 0% ,50%, 100% to {
  transform: translate(0, 0) rotate(0deg);
}
50% {
    transform: translate(70px, 0) rotate(360deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
`;
const Square = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 4px;
  background-color: #fbb400;
  animation: ${loadingG} 1.5s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
`;

const PreviewPage = ({ isPreview }) => {
  if (isPreview === false) return "Not a preview!";

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Square />
    </div>
  );
};

const Preview = (props) => {
  const {
    data: {
      sitePlugin: {
        pluginOptions: { repositoryName },
      },
    },
  } = props;

  return withPreviewResolver(PreviewPage, {
    repositoryName,
    linkResolver: () => (doc) => linkResolver(doc),
  })(props);
};

export default Preview;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    sitePlugin(name: { eq: "gatsby-source-prismic" }) {
      pluginOptions
    }
  }
`;
