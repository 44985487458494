const linkResolver = (doc) => {
  if (doc.type === "post-blog") {
    return `/blog/${doc.uid}`;
  }
  if (doc.type === "post-app") {
    return `/mobile/${doc.uid}`;
  }
  return `/`;
};

module.exports = linkResolver;
